<template>
  <div>
    <b-card>
      <div class="mb-1">
        <b-button
          variant="success"
          class="pull-right"
          @click="onNewUserClick"
        >
          Nuevo Usuario
        </b-button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="users"
        :pagination-options="{
          enabled: true,
          perPage: 10
        }"
        :search-options="{
          enabled: true,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'name'"
          >
            <router-link :to="{ name: 'admin-users-edit', params: { id: props.row.id } }">
              <span>{{ props.row.name }}</span>
            </router-link>
          </span>
        </template>
      </vue-good-table>
    </b-card>

  </div>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import users from '../../../services/users'

export default {
  components: {
    BCard,
    BButton,
    VueGoodTable,
  },
  data() {
    return {
      users: [],
      columns: [
        {
          label: 'Nombre',
          field: 'name',
        },
        {
          label: 'Correo electrónico',
          field: 'email',
        },
        {
          label: 'Teléfono',
          field: 'phone',
        },
        {
          label: 'Tipo',
          field: 'type',
        },
      ],
    }
  },
  firebase: {
    users: users.bindAll(),
  },
  methods: {
    onNewUserClick() {
      this.$router.push({ name: 'admin-users-create' })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
